export const baseColors = {
    gray: '#FEF9EF',
    yellow: '#FFCB77',
    lightGreen: '#17C3B2',
    blue: '#227C9D',
    red: '#FE6D73'
}

export const logisticMethod = {
    cdek: 'cdek',
    post: 'post',
}

export const initialFormValues = {
    fullname: '',
    phone: '',
    city: '',
    logisticMethod: logisticMethod.cdek,
    pvz: '',
    note: '',
    index: '',
    address: '',
    zipcode: ''
}