import { Box, Typography } from "@mui/material"
import { baseColors } from "../../utils/consts"

export const CategoryItem = ({ name, imgSrc, categoryId, handleCategoryClick, activeCategory }) => (
    <Box onClick={() => handleCategoryClick(categoryId)} sx={{
        ":hover": {
            border: `2px solid ${baseColors.blue}`,
            cursor: 'pointer'
        },
        width: '120px',
        borderRadius: '10px',
        m: '10px',
        border: activeCategory === categoryId ? `2px solid ${baseColors.blue}` : '2px solid transparent'
    }}>
        <Box
            component="img"
            sx={{
                height: 100,
                width: 100,
                m: '0 auto'
            }}
            alt="Фото категории"
            src={`${process.env.REACT_APP_API_URL}${imgSrc}`}
        />
        <Typography sx={{
            textAlign: 'center'
        }}>{name}</Typography>
    </Box>)