import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

const accessTokenController = {
  save: (token) => {
    Cookies.set("accessToken", token);
  },

  logout: () => {
    Cookies.remove("accessToken");
  },

  getToken: () => {
    const accessToken = Cookies.get("accessToken");
    return accessToken || "";
  },

  decodeToken(token) {
    return jwtDecode(token);
  },
};

export default accessTokenController;
