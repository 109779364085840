import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { TailSpin } from 'react-loader-spinner';

import api from '../../services/api';

const CurrentSendlerList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sendlerList, setSendlerList] = useState([]);

  const columns = [
    { field: 'title', headerName: 'Название', width: 70, flex: 0.2 },
    {
      field: 'status',
      headerName: 'Статус',
      width: 90,
      flex: 0.2,
    },
    {
      field: 'userList',
      headerName: 'Кол-во подписчиков в рассылке',
      width: 90,
      flex: 0.2,
      valueGetter: (params) => params.row.userList.length,
    },
    {
      field: 'content',
      headerName: 'Контент',
      width: 90,
      flex: 0.2,
      valueGetter: (params) => (params.row.content ? 'Задан' : 'Не задан'),
    },
    {
      field: 'sendlerId',
      headerName: 'Действие',
      width: 90,
      flex: 0.2,
      renderCell: (params) => getCurrentActionButton(params),
    },
    {
      field: 'errorUserList',
      headerName: 'Ошибки рассылки',
      width: 90,
      flex: 0.2,
      valueGetter: (params) => (params.row.errorUserList?.length || 'Неизвестно'),
    },
    {
      field: 'successUserList',
      headerName: 'Успешные доставки',
      width: 90,
      flex: 0.2,
      valueGetter: (params) => (params.row.successUserList?.length || 'Неизвестно'),
    },
  ];

  const handleSendlerStart = (sendlerId) => {
    api.startSendler(sendlerId).then(() => {
      window.location.reload();
    });
  };

  const handleSendlerDelete = (sendlerId) => {
    api.deleteSendler(sendlerId).then(() => {
      window.location.reload();
    });
  };

  const handleQueueStop = (sendlerId) => {
    api.stopSendler(sendlerId).then(() => {
      window.location.reload();
    });
  }

  const getCurrentActionButton = (params) => {
    switch (params.row.status) {
      case 'created':
        return (
          <>
            <Button
              variant="contained"
              disabled={!Boolean(params.row.content)}
              onClick={() => handleSendlerStart(params.row.sendlerId)}
            >
              Начать
            </Button>
          </>
        );

      case 'inwork':
        return (
          <Button color="warning" variant="contained" onClick={() => handleQueueStop(params.row.sendleId)}>
            Остановить
          </Button>
        );

      case 'completed':
        return (
          <Button color="error" variant="contained" onClick={() => handleSendlerDelete(params.row.sendlerId)}>
            Удалить
          </Button>
        );

      default:
        return (
          <Button color="error" variant="contained" onClick={() => handleSendlerDelete(params.row.sendlerId)}>
            Удалить
          </Button>
        );
    }
  };

  useEffect(() => {
    setIsLoading(true);
    api.getAllSendlers().then((result) => {
      setSendlerList(result);
      setIsLoading(false);
    });
  }, []);

  return (
    <Box sx={{ height: '600px', width: '100%' }}>
      {isLoading ? (
        <TailSpin wrapperStyle={{ justifyContent: 'center', margin: '100px 0' }} />
      ) : (
        <DataGrid getRowId={row => row.sendlerId} columns={columns} rows={sendlerList} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}/>
      )}
    </Box>
  );
};

export default CurrentSendlerList;
