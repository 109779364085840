import { Grid, Button, Container, Stack, Typography } from '@mui/material';

import { useState, useEffect } from 'react';
import api from '../services/api';
import Iconify from '../components/iconify';

import Modal from '../components/Modal/Modal';
import NewSendlerModalContent from '../components/NewSendlerModalContent';
import CurrentSendlerList from '../components/CurrentSendlerList/CurrentSendlerList';
import withAuth from '../components/AuthHOC';

function SendlerPage() {
  const [newSendlerModalOpen, setNewSendlerModalOpen] = useState(false);

  const handleAddNewSendler = () => {
    setNewSendlerModalOpen(true);
  }

  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Рассылки
          </Typography>
          <Modal contentWidth='600px' buttonText={'Новая рассылка'} isModalOpen={newSendlerModalOpen} handleClickOpen={handleAddNewSendler} handleClose={() => setNewSendlerModalOpen(false)}>
            <NewSendlerModalContent />
          </Modal>
        </Stack>

        <CurrentSendlerList />
      </Container>
    </>
  );
}

export default withAuth(SendlerPage);
