import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import useResponsive from '../../../hooks/useResponsive';
import api from '../../../services/api';
import { getColumns } from './categories-table-columns';

const CategoriesTable = () => {
  const [categories, setCategories] = useState([]);

  const isSm = useResponsive('down', 'sm');

  useEffect(() => {
    api
      .getAllCategories()
      .then((data) => {
        if (data) {
          setCategories(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box sx={{ height: 500, width: '100%' }}>
      <DataGrid rowHeight={100} rows={categories} columns={getColumns(isSm)} getRowId={(row) => row.categoryId} />
    </Box>
  );
};

export default CategoriesTable;
