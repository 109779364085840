
import Cookies from "js-cookie";

const cacheController = {
    save: (basket) => {
        Cookies.set("basket", JSON.stringify(basket), { expires: 7 });
    },

    getBasket: () => {
        const basket = Cookies.get("basket");
        return JSON.parse(basket || "[]");
    },

    clear() {
        return Cookies.remove('basket');
    },
};

export default cacheController;
