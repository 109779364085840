import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { useEffect, useState } from 'react';
// @mui
import {
  Stack,
  Button,
  Container,
  Typography,
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ColorRing } from 'react-loader-spinner';
import { useSearchParams } from "react-router-dom";

import api from '../services/api';
import { baseColors, initialFormValues, logisticMethod } from '../utils/consts';
import { CategoryItem } from '../components/CategoryItem/CategoryItem';
import { ValidationSchemaCalcLead } from '../components/validationSchemaCalcLead';
import { NavBar } from '../components/NavBar';
import { Cart } from '../components/Cart';
import useResponsive from '../hooks/useResponsive';
import FAQContent from '../components/FAQContent';
import cacheController from '../services/cacheController';

const stepList = {
  calc: 'calc',
  lead: 'lead',
  loading: 'loading',
  success: 'success',
  manager: 'manager',
  faq: 'faq',
  reviews: 'reviews',
}

export default function CalcPage() {
  const [activeCategory, setActiveCategory] = useState('');
  const [link, setLink] = useState('');
  const [itemCost, setItemCost] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(stepList.loading);
  const [categoryList, setCategoryList] = useState([]);
  const [cart, setCart] = useState([]);
  const [name, setName] = useState('');
  const [size, setSize] = useState('');

  const [currentCourse, setCurrentCourse] = useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const WebAppControll = window.Telegram?.WebApp;

  const isSm = useResponsive('down', 'sm');

  WebAppControll?.expand();

  useEffect(() => {
    async function fetchData() {
      setCurrentStep(stepList.loading);
      const [rawCategory, courseResp] = await Promise.all([api.getAllCategories(), api.getRate()]);
      setCategoryList(rawCategory);
      setActiveCategory(rawCategory[0]?.categoryId);
      setCurrentCourse(courseResp?.rate);
      setCurrentStep(stepList.calc);
      // axios.get('https://www.cbr-xml-daily.ru/latest.js').then(res => {
      //   setCurrentCourse(1 / res.data.rates.CNY);
      //   setCurrentStep(stepList.calc);
      // })
    }

    fetchData();
  }, []);

  useEffect(() => {
    const cacheCart = cacheController.getBasket();

    if (cacheCart && cacheCart.length) {
      setCart(cacheCart);
    }
  }, [])

  const handleFormSubmit = async (evt) => {
    try {
      setIsLoading(true);
      const result = await api.createLead({
        ...evt,
        username: searchParams.get('username'),
        cart,
      });

      setIsLoading(false);

      if (result) {
        setCurrentStep(stepList.success);
        setCart([])
        cacheController.clear();
      }
    } catch (error) {
      console.log(error, 'error createLead');
    }
  }

  const handleAddItemToCart = () => {
    const newCartItem = {
      cartId: uuidv4(),
      itemCost,
      size,
      name,
      totalItemCost: calculateDelivery(),
      category: categoryList.find(el => el.categoryId === activeCategory)?.name,
      link,
    }
    if (cart.length) {
      setCart(prevState => {
        cacheController.save(prevState.concat([newCartItem]));
        return prevState.concat([newCartItem])
      });

    } else {
      setCart([newCartItem]);
      cacheController.save([newCartItem]);
    }


    setLink('');
    setName('');
    setSize('');
    setItemCost('');
    console.log('before cart save');
    setActiveCategory(categoryList[0]?.categoryId)
  };

  const handleCartItemDelete = (cartItemId) => {
    setCart(prev => {
      cacheController.save(prev.filter(el => el.cartId !== cartItemId));
      return prev.filter(el => el.cartId !== cartItemId);
    });
  }

  const isButtonDisabled = !Boolean(itemCost) || !Boolean(link) || !Boolean(size) || !Boolean(name) || link.includes('globalpoizonx');

  const getCurrentScreen = () => {
    switch (currentStep) {
      case stepList.loading:
        return <ColorRing
          visible
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{ display: 'flex', alignSelf: 'center', }}
          colors={Object.values(baseColors)}
        />

      case stepList.calc:
        return (
          <Stack direction={'column'} spacing={2} alignContent={'center'} width={'285px'}>
            <Stack direction={'row'} justifyContent={'center'} flexWrap={'wrap'}>
              {categoryList.map(el => <CategoryItem
                name={el.name}
                key={el.categoryId}
                imgSrc={el.image}
                categoryId={el.categoryId}
                activeCategory={activeCategory}
                handleCategoryClick={(categoryId) => setActiveCategory(categoryId)}
              />)}
            </Stack>
            <Typography>Актуальный курс юаня: <b>{currentCourse.toFixed(1)}</b></Typography>
            <TextField value={itemCost} type='number' label={'Цена в юанях'} onChange={(evt) => setItemCost(evt.target.value)} />
            <TextField value={link} label={'Ссылка на товар'} error={link.includes('globalpoizonx')} helperText={link.includes('globalpoizonx') ? 'Сайт globalpoizonx.com фейк. Ознакомьтесь с инструкцией в разделе FAQ' : ''} onChange={(evt) => setLink(evt.target.value)} />
            <TextField value={name} label={'Название'} onChange={(evt) => setName(evt.target.value)} />
            <TextField value={size} label={'Размер'} onChange={(evt) => setSize(evt.target.value)} />
            {
              itemCost &&
              (<Typography>{`Цена с учетом доставки: ${calculateDelivery()} ₽`}</Typography>)
            }
            <Button sx={{ mt: '20px' }} variant='contained' disabled={isButtonDisabled} onClick={handleAddItemToCart}>В корзину</Button>
          </Stack>
        )

      case stepList.lead:
        return (
          <Stack direction={'column'} spacing={2} alignContent={'center'} width={'285px'}>
            <Cart cart={cart} handleDelete={handleCartItemDelete} />
            <Formik initialValues={initialFormValues} onSubmit={handleFormSubmit} validationSchema={ValidationSchemaCalcLead}>
              {({ handleSubmit, values, errors, touched, isSubmitting, handleChange }) => {
                const fullnameHelperText = errors.fullname && touched.fullname ? errors.fullname : '';
                const phoneHelperText = errors.phone && touched.phone ? errors.phone : '';
                const cityHelperText = errors.city && touched.city ? errors.city : '';
                const pvzHelperText = errors.pvz && touched.pvz ? errors.pvz : '';
                const addressHelperText = errors.address && touched.address ? errors.address : '';
                const zipcodeHelperText = errors.zipcode && touched.zipcode ? errors.zipcode : '';
                const noteHelperText = errors.note && touched.note ? errors.note : '';

                return (
                  <form onSubmit={handleSubmit}>
                    <Typography variant='h5' my={'20px'}>Заявка</Typography>
                    <Stack direction={'column'} spacing={2} alignContent={'center'} width={'285px'}>
                      <TextField error={errors.fullname && touched.fullname} helperText={fullnameHelperText} value={values.fullname} name='fullname' label={'ФИО'} onChange={handleChange} />
                      <TextField error={errors.phone && touched.phone} helperText={phoneHelperText} value={values.phone} name='phone' label={'Телефон'} onChange={handleChange} />
                      <TextField error={errors.city && touched.city} helperText={cityHelperText} value={values.city} name='city' label={'Город'} onChange={handleChange} />

                      <ToggleButtonGroup
                        color="primary"
                        value={values.logisticMethod}
                        exclusive

                        onChange={handleChange}
                        aria-label="Platform"
                        fullWidth
                      >
                        <ToggleButton name='logisticMethod' value={logisticMethod.cdek}>СДЭК</ToggleButton>
                        <ToggleButton name='logisticMethod' value={logisticMethod.post}>Почта России</ToggleButton>
                      </ToggleButtonGroup>
                      {
                        // ссылку на карту адресов с учетом введенного города?
                        values.logisticMethod === logisticMethod.cdek && (
                          // Дадату на адрес?
                          <TextField name='pvz' error={errors.pvz && touched.pvz} helperText={pvzHelperText} value={values.pvz} label={'Адрес ближайшего СДЭКа'} onChange={handleChange} />
                        )}
                      {
                        // ссылку на карту адресов с учетом введенного города?
                        values.logisticMethod === logisticMethod.post && (
                          <>
                            <TextField name='address' error={errors.address && touched.address} helperText={addressHelperText} value={values.address} label={'Адрес доставки'} onChange={handleChange} />
                            <TextField name='zipcode' error={errors.zipcode && touched.zipcode} helperText={zipcodeHelperText} value={values.zipcode} label={'Индекс'} onChange={handleChange} />
                          </>
                        )}
                      <TextField multiline name='note' error={errors.note && touched.note} helperText={noteHelperText} placeholder='Размер, цвет или что-то другое, что поможет нам при выкупе товара (необязательно)'
                        rows={4} value={values.note} label={'Комментарий'} onChange={handleChange} />
                      <LoadingButton sx={{ mt: '20px' }} variant='contained' disabled={!cart.length} loading={isLoading} type='submit'>Заказать</LoadingButton>
                    </Stack>
                  </form>
                );
              }}
            </Formik>
          </Stack>
        )

      case stepList.success:
        return (
          <Stack direction={'column'} textAlign={'center'} spacing={2} justifyContent={'center'} alignItems={'center'} width={'285px'}>
            <CheckCircleOutlineIcon sx={{
              color: baseColors.lightGreen,
              width: '75px',
              height: '75px'
            }} />
            <Typography variant='h4'>Ваша заявка принята.</Typography>
            <Typography>Мы свяжемся с Вами<br />в ближайшее время.</Typography>
            <Button onClick={() => WebAppControll.initData ? WebAppControll.close() : setCurrentStep(stepList.calc)}>Назад</Button>
          </Stack>
        )

      case stepList.manager:
        return (
          <Stack direction={'column'} textAlign={'center'} spacing={2} justifyContent={'center'} alignItems={'center'} width={'285px'}>
            <Typography variant='h6' mt={'10px'}>Ответим на все Ваши вопросы, проконсультируем и поможем оформить заказ</Typography>
            <Button variant='contained' sx={{
              background: baseColors.lightGreen,
            }} onClick={() => WebAppControll.openTelegramLink('https://t.me/grebizmanager')}>Написать</Button>

            <Button variant='contained' sx={{
              background: baseColors.lightGreen,
            }} onClick={() => WebAppControll.openTelegramLink('https://t.me/+aLEmexDa_jdiZWQy')}>Основной канал</Button>
          </Stack>
        )

      case stepList.faq:
        return (
          <Stack direction={'column'} textAlign={'center'} spacing={2} width={'285px'}>
            <FAQContent />
          </Stack>
        )

      case stepList.reviews:
        return (
          <Stack direction={'column'} textAlign={'center'} spacing={2} justifyContent={'center'} alignItems={'center'} width={'285px'}>
            <Typography>Наш канал с отзывами</Typography>
            <Button variant='contained' sx={{
              background: baseColors.lightGreen,
            }} onClick={() => WebAppControll.openTelegramLink('https://t.me/grebizfeedback')}>Перейти</Button>
          </Stack>
        )

      default:
        return <ColorRing
          visible
          height="80"
          width="80"
          wrapperStyle={{ display: 'flex', alignSelf: 'center', }}
          colors={Object.values(baseColors)}
        />
    }
  }

  const calculateDelivery = () => {
    console.log(categoryList, categoryList.find(el => el.categoryId === activeCategory));
    const getActiveCategoryBaseCost = () => {
      if (Number(itemCost) < 200) {
        return categoryList.find(el => el.categoryId === activeCategory)?.baseCost
      }
      if (Number(itemCost) < 400 && Number(itemCost) >= 200) {
        return categoryList.find(el => el.categoryId === activeCategory)?.baseCost400
      }

      if (Number(itemCost) < 600 && Number(itemCost) >= 400) {
        return categoryList.find(el => el.categoryId === activeCategory)?.baseCost600
      }

      if (Number(itemCost) < 1000 && Number(itemCost) >= 600) {
        return categoryList.find(el => el.categoryId === activeCategory)?.baseCost1000
      }

      if (Number(itemCost) < 2000 && Number(itemCost) >= 1000) {
        return categoryList.find(el => el.categoryId === activeCategory)?.baseCost2000
      }

      if (Number(itemCost) >= 2000) {
        return categoryList.find(el => el.categoryId === activeCategory)?.baseCost2000up
      }

      return categoryList.find(el => el.categoryId === activeCategory)?.baseCost
    }

    const sum = Math.ceil((Number(itemCost) * currentCourse.toFixed(1) + Number(getActiveCategoryBaseCost())))

    return sum + (Number(itemCost) >= 2000 ? + sum * 0.02 : 0);
  }

  const handleScreenChange = (newStep) => {
    setCurrentStep(newStep);
  }

  return (
    <>
      <Container sx={{
        height: '100%',
      }}>
        <Box maxWidth={isSm ? 'sm' : 'xl'} sx={{
          height: 'calc(100% - 60px)',
          display: 'flex',
          justifyContent: 'center',
          overflowY: isSm ? 'scroll' : 'auto',
          overflowX: 'visible',
          p: '0',
        }}>
          {getCurrentScreen()}
        </Box>
        <NavBar onChange={handleScreenChange} currentStep={currentStep} stepList={stepList} cart={cart} />
      </Container>
    </>
  );
}
