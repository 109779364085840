import { useState } from 'react';
import { Box, Button, Modal, Paper, Stack, TextField, Typography } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import api from '../../services/api';
import { ImagePreview } from './ImagePreview';

const CreateCategoryModal = ({ open, onClose }) => {
  const [name, setName] = useState('');
  const [baseCost, setBaseCost] = useState(null);
  const [baseCost400, setBaseCost400] = useState(null);
  const [baseCost600, setBaseCost600] = useState(null);
  const [baseCost1000, setBaseCost1000] = useState(null);
  const [baseCost2000, setBaseCost2000] = useState(null);
  const [baseCost2000up, setBaseCost2000up] = useState(null);
  const [image, setImage] = useState(null);

  const handleCreateCategorySubmit = async () => {
    const formData = new FormData();
    
    if (!baseCost || !baseCost400 || !baseCost600 || !baseCost1000 || !baseCost2000 || !baseCost2000up) {

      window.alert('Заполните все поля с ценой');
      return;
    }

    formData.append('name', name);
    formData.append('baseCost', baseCost);
    formData.append('baseCost400', baseCost400);
    formData.append('baseCost600', baseCost600);
    formData.append('baseCost1000', baseCost1000);
    formData.append('baseCost2000', baseCost2000);
    formData.append('baseCost2000up', baseCost2000up);
    formData.append('image', image);

    await api.createCategory(formData);

    window.location.reload();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={{ width: '350px', m: '100px auto', }}>
        <Stack
          direction={'column'}
          spacing={3}
          sx={{
            p: '20px',
          }}
        >
          <Typography variant="h5">Новая категория</Typography>
          <TextField label={'Название категории'} onChange={(evt) => setName(evt.target.value)} />
          <Stack direction={'row'}>
            <Stack direction={'column'} mr='15px' gap={'10px'}>
              <TextField label={'Цена до 200'} type="number" onChange={(evt) => setBaseCost(evt.target.value)} />
              <TextField label={'Цена до 400'} type="number" onChange={(evt) => setBaseCost400(evt.target.value)} />
              <TextField label={'Цена до 600'} type="number" onChange={(evt) => setBaseCost600(evt.target.value)} />
            </Stack>

            <Stack direction={'column'} gap={'10px'}>
              <TextField label={'Цена до 1000'} type="number" onChange={(evt) => setBaseCost1000(evt.target.value)} />
              <TextField label={'Цена до 2000'} type="number" onChange={(evt) => setBaseCost2000(evt.target.value)} />
              <TextField label={'Цена от 2000'} type="number" onChange={(evt) => setBaseCost2000up(evt.target.value)} />
            </Stack>
          </Stack>

          {image && <ImagePreview image={image} />}
          <Button variant="contained" component="label">
            Загрузить картинку
            <AddAPhotoIcon />
            <input type="file" hidden onChange={(evt) => setImage(evt.target.files[0])} />
          </Button>
          <Button variant="contained" onClick={handleCreateCategorySubmit}>
            Создать категорию
          </Button>
        </Stack>
      </Paper>
    </Modal>
  );
};

export default CreateCategoryModal;
