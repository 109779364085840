import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import api from '../../../services/api';
import accessTokenController from '../../../services/accessTokenController';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickLogin = () => {
    setError('');

    api
      .login({ login, password })
      .then((data) => {
        if (data) {
          accessTokenController.save(data.token);
          navigate('/dashboard', { replace: true });
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message;
        if (errorMessage) {
          setError(errorMessage);
        }
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Логин" value={login} onChange={(evt) => setLogin(evt.target.value)} />

        <TextField
          name="password"
          label="Пароль"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {error && (
        <Typography component="body2" color="error">
          {error}
        </Typography>
      )}

      <LoadingButton
        sx={{ mt: '25px' }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClickLogin}
      >
        Login
      </LoadingButton>
    </>
  );
}
