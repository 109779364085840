import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { Stack, Button, Container, Typography, TextField } from '@mui/material';

import api from '../services/api';
import useResponsive from '../hooks/useResponsive';
import CategoriesTable from '../components/categories/categories-table/categories-table';
import CreateCategoryModal from '../components/create-category-modal/create-category-modal';
import withAuth from '../components/AuthHOC';

function DashboardAppPage() {
  const [open, setOpen] = useState(false);
  const [rate, setRate] = useState(undefined);
  const [userList, setUserList] = useState([]);


  const isSm = useResponsive('down', 'sm');

  useEffect(() => {
    api.getRate().then(({ rate }) => setRate(rate))
  }, []);

  useEffect(() => {
    api.getAllUsers().then((result) => {
      setUserList(result);
    });
  }, []);

  const handleRateChange = async (evt) => {
    setRate(evt.target.value);
    api.changeRate(evt.target.value);
    debounce(window.location.reload, 500)
  }

  return (
    <>
      <Container maxWidth="xl">
        <Stack flexDirection={isSm ? 'column' : 'row'} justifyContent={'space-between'} spacing={2} mb={'20px'}>
          <Typography variant="h4" sx={{ mb: isSm ? 'none' : '20px' }}>
            Категории
          </Typography>
          <TextField type='number' InputLabelProps={{ shrink: true }} label={'Заданный курс Юаня'} value={rate} onChange={handleRateChange} />
          <Button
            variant="contained"
            color="success"
            sx={{ color: '#fff', height: '40px' }}
            onClick={() => setOpen(true)}
          >
            Создать категорию
          </Button>
        </Stack>
        <Typography variant="h4">
            Активаций бота: {userList.length || ''} 
          </Typography>
        <CategoriesTable />
        <CreateCategoryModal open={open} onClose={() => setOpen(false)} />
      </Container>
    </>
  );
}

export default withAuth(DashboardAppPage);
