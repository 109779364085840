import React from 'react';
import { Navigate, redirect } from "react-router-dom";

import accessTokenController from '../services/accessTokenController';

const withAuth = (WrappedComponent) => {
  const AuthenticatedComponent = (props) => {
    const token = accessTokenController.getToken();
    const isAuthenticated = Boolean(token);
    return isAuthenticated ? <WrappedComponent {...props} /> : <Navigate to="/login" replace />;
  };
  return AuthenticatedComponent;
};

export default withAuth;