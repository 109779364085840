import * as React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Link } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

export default function FAQContent() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box mt={'20px'}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>Как рассчитать конечную стоимость товара с доставкой до Москвы?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>С этим Вам поможет наш бот @grebizbot</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>Как оформить заказ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Для этого Вам нужно при расчете в боте @grebizbot добавить желаемые товары или товар в корзину. После этого
            в корзине указать все свои данные и отправить заявку. Далее с Вами свяжется менеджер для уточнения деталей и
            оплаты.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>Где взять ссылку на товар?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            В приложении DEWU на страничке товара есть вот такой <OpenInNewIcon /> или вот такой{' '}
            <Box
              component="img"
              sx={{
                m: '0 auto',
              }}
              alt="Иконка вичата"
              src={`${process.env.REACT_APP_API_URL}/assets/images/wechat.png`}
            />{' '}
            значок. При нажатии на него, снизу появляется экран на котором есть вот такой значок <LinkIcon />. При
            нажатии на него будет скопирована ссылка, её и нужно вставить в бота.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>Какую цену указывать?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Указывайте цену согласно размеру, также обратите внимание на доставку. Есть бирюзовая кнопка (более быстрая
            и платная доставка по Китаю) и черная (бесплатная, но немного длительнее доставка по Китаю). При выборе
            размера на этих кнопках будет указана цена.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>Как быстро выкупается товар?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Выкуп Вашего товара происходит в тот же, либо на следующий день после оплаты и оформления заказа.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>Сколько времени занимает доставка?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Доставка занимает 2-3 недели, но может затянуться до 3-4 недель. 
Пока товар идет в Россию, его отследить нельзя. Трек-код Вы получите когда товар будет отправлен Вам со склада в Москве.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>Можно ли отправить напрямую ко мне, в регион, не завозя посылку в Москву?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Нет, в первую очередь, все Ваши товары идут на наш склад в Москве, после этого сортируются, проверяются и отправляются по регионам либо странам к Вам.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>Как подобрать размер?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Лучше всего замерить свои параметры и сравнить их с таблицей, указанной в описании под каждым товаром в приложении DEWU.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>Можно ли вернуть деньги после заказа?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Если вещь еще не выкуплена, да. Если выкуп уже был совершен, тогда не получится</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>Какими службами доставки Вы отправляете?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Мы пользуемся службой СДЕК и Почтой России.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>Что-то пошло не так или сломалось</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography>Напишите пожалуйста нам, мы постараемся помочь <Link type='email' href='mailto:grebizmanager@gmail.com'>grebizmanager@gmail.com</Link></Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
