import { Box } from '@mui/material';

export const ImagePreview = ({ image }) => {
  return (
    <Box
      component="img"
      sx={{
        height: 100,
        width: 100,
        m: '0 auto',
      }}
      src={URL.createObjectURL(image)}
      alt={'Картинка новой категории'}
    />
  );
};
