import { Box, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api, { BASE_URL } from '../../../services/api';



export const getColumns = (isSm) => {
  return [
    {
      field: 'image',
      headerName: 'Картинка',
      width: 100,
      renderCell: (params) =>
        <Box
          component="img"
          sx={{
            minHeight: 100,
            minWidth: 100,
            height: 100,
            width: 100,
            m: '0 auto'
          }}
          src={`${BASE_URL}${params.value}`}
          alt={params.row.name}
        />
    },
    {
      field: 'name',
      headerName: 'Название',
      flex: 0.2,
    },
    {
      field: 'baseCost',
      headerName: 'Базовая цена',
      type: 'number',
      flex: 0.2,
      headerAlign: 'left',
      align: 'left',
    },
    {
      id: 'delete',
      field: 'actions',
      headerName: 'Действие',
      flex: 0.2,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) =>
        isSm ?
          <IconButton onClick={() => {
            api.deleteCategory(params.id);
            window.location.reload();
          }} color="error">
            <DeleteIcon />
          </IconButton>
          : <Button
            variant="contained"
            color="error"
            onClick={() => {
              api.deleteCategory(params.id);
              window.location.reload();
            }}
          >
            Удалить
          </Button>

      ,
    },
  ];
}