import { Box, Card, TextField, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import api from '../../services/api';

const NewSendlerModalContent = () => {
  const [sendlerTitle, setSendlerTitle] = useState('');
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sendlerUserList, setSendlerUserList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    api.getAllUsers().then((result) => {
      setUserList(result);
      setIsLoading(false);
    });
  }, []);

  const columns = [{ field: 'userId', headerName: 'userId', width: 70, flex: 0.2 }];

  const handleSendlerCreate = async () => {
    await api.createSendler({
      userList: sendlerUserList,
      title: sendlerTitle,
    });

    window.location.reload();
  };

  return (
    <Box>
      <TextField
        value={sendlerTitle}
        onChange={(evt) => setSendlerTitle(evt.target.value)}
        label={'Название рассылки'}
        required
        sx={{
          mb: '20px',
        }}
      />
      <Card
        sx={{
          height: '400px',
        }}
      >
        {isLoading ? (
          <TailSpin wrapperStyle={{ justifyContent: 'center', margin: '100px 0' }} />
        ) : (
          <>
            <DataGrid
              getRowId={(row) => row.userId}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSendlerUserList(newRowSelectionModel);
              }}
              localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
              checkboxSelection
              disableRowSelectionOnClick
              columns={columns}
              rows={userList}
            />
          </>
        )}
      </Card>

      <Button variant="contained" fullWidth sx={{ mt: '20px' }} onClick={handleSendlerCreate}>
        Создать
      </Button>
    </Box>
  );
};

export default NewSendlerModalContent;
