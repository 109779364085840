import { IconButton, Stack, Typography } from '@mui/material';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import DeleteIcon from '@mui/icons-material/Delete';

export const Cart = ({ cart, handleDelete }) => {
  const CartItem = ({ cartElem }) => {
    const { cartId, name, size, category, itemCost, totalItemCost } = cartElem;
    return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack>
          <Typography variant="h6">{category}</Typography>
          <Typography maxWidth={'200px'} noWrap>{`${name} ${size}`}</Typography>
          <Typography>Цена в юанях:&nbsp;{itemCost}&nbsp;¥</Typography>
          <Typography>Цена в рублях:&nbsp;{totalItemCost}&nbsp;₽</Typography>
        </Stack>
        <IconButton onClick={() => handleDelete(cartId)}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  };

  if (!cart.length) {
    return null;
  }

  const totalCartAmount = cart.reduce((acc, curr) => Number(curr.totalItemCost) + acc, 0)

  return (
    <>
      <Typography variant="h4">Корзина</Typography>
      {cart.map((el, ind) => (
        <CartItem cartElem={el} ind={ind} key={ind} />
      ))}
      <Typography variant="h6">Итог:&nbsp;{totalCartAmount}&nbsp;₽</Typography>
    </>
  );
};
