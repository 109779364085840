import { AppBar, Badge, Stack, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReviewsIcon from '@mui/icons-material/Reviews';
import CalculateIcon from '@mui/icons-material/Calculate';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { baseColors } from '../utils/consts';

export const NavBar = ({ onChange, currentStep, stepList, cart }) => (
  <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, height: '55px', p: '2.5px' }}>
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      sx={{
        flexBasis: '50px',
        flexShrink: '1',
        flexGrow: '0',
      }}
    >
      <Stack
        alignItems={'center'}
        onClick={() => onChange(stepList.manager)}
        p={'3px'}
        sx={{
          borderRadius: '5px',
          width: '20%',
          px: '5px',
        }}
        border={stepList.manager === currentStep ? '1px solid #FFFFFF' : '1px solid transparent'}
      >
        <AccountCircleIcon />
        <Typography textAlign={'center'} fontSize={'12px'}>
          Менеджер
        </Typography>
      </Stack>
      <Stack
        alignItems={'center'}
        onClick={() => onChange(stepList.reviews)}
        p={'3px'}
        sx={{
          borderRadius: '5px',
          width: '20%',
          px: '5px',
        }}
        border={stepList.reviews === currentStep ? '1px solid #FFFFFF' : '1px solid transparent'}
      >
        <ReviewsIcon />
        <Typography textAlign={'center'} fontSize={'12px'}>
          Отзывы
        </Typography>
      </Stack>
      <Stack
        alignItems={'center'}
        onClick={() => onChange(stepList.calc)}
        p={'3px'}
        sx={{
          borderRadius: '5px',
          width: '20%',
          px: '5px',
        }}
        border={stepList.calc === currentStep ? '1px solid #FFFFFF' : '1px solid transparent'}
      >
        <CalculateIcon />
        <Typography textAlign={'center'} fontSize={'12px'}>
          Расчет
        </Typography>
      </Stack>
      <Stack
        alignItems={'center'}
        onClick={() => onChange(stepList.lead)}
        p={'3px'}
        sx={{
          borderRadius: '5px',
          width: '20%',
          px: '5px',
        }}
        border={stepList.lead === currentStep ? '1px solid #FFFFFF' : '1px solid transparent'}
      >
        <Badge badgeContent={cart.length || null} color="error">
          <AddShoppingCartIcon />
        </Badge>
        <Typography textAlign={'center'} fontSize={'12px'}>
          Заказ
        </Typography>
      </Stack>
      <Stack
        alignItems={'center'}
        onClick={() => onChange(stepList.faq)}
        p={'3px'}
        sx={{
          borderRadius: '5px',
          width: '20%',
          px: '5px',
        }}
        border={stepList.faq === currentStep ? '1px solid #FFFFFF' : '1px solid transparent'}
      >
        <LiveHelpIcon />
        <Typography textAlign={'center'} fontSize={'12px'}>
          FAQ
        </Typography>
      </Stack>
    </Stack>
  </AppBar>
);
