import axios from 'axios';
import propertyOf from 'lodash/propertyOf';

import accessTokenController from './accessTokenController';

export const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

export const createApi = () => {
  const api = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
    timeout: 45000,
  });

  // TODO; сделать на refreshToken
  api.interceptors.request.use(
    (request) => {
      // Подставляем всегда свеженький токен
      const accessToken = accessTokenController.getToken();
      request.headers.Authorization = `Bearer ${accessToken}`;

      return request;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const isBadToken = propertyOf(error)(['response', 'data', 'error_code']) === 1005;

      if (isBadToken) {
        accessTokenController.logout();
      }

      return Promise.reject(error);
    }
  );

  return api;
};

const api = {
  async getAllCategories() {
    const apiAxios = createApi();
    const response = await apiAxios.get(`${BASE_URL}/category`);

    return response.data;
  },

  async getRate() {
    const apiAxios = createApi();
    const response = await apiAxios.get(`${BASE_URL}/rate`);

    return response.data;
  },

  async changeRate(rateValue) {
    const apiAxios = createApi();
    const response = await apiAxios.post(`${BASE_URL}/rate/${rateValue}`);

    return response.data;
  },

  async login(userLoginData) {
    const apiAxios = createApi();
    const response = await apiAxios.post(`${BASE_URL}/login`, {
      userLogin: userLoginData.login,
      userPassword: userLoginData.password,
    });

    return response.data;
  },

  async createLead(leadData) {
    const apiAxios = createApi();
    const response = await apiAxios.post(`${BASE_URL}/lead`, leadData);

    return response.data;
  },

  async createSendler(sendlerData) {
    const apiAxios = createApi();
    const response = await apiAxios.post(`${BASE_URL}/sendler`, sendlerData);

    return response.data;
  },

  async deleteSendler(sendlerId) {
    const apiAxios = createApi();
    const response = await apiAxios.delete(`${BASE_URL}/sendler/${sendlerId}`);

    return response.data;
  },

  async startSendler(sendlerId) {
    const apiAxios = createApi();
    const response = await apiAxios.patch(`${BASE_URL}/sendler/${sendlerId}`);

    return response.data;
  },

  async createCategory(categoryData) {
    const apiAxios = createApi();
    const response = await apiAxios.post(`${BASE_URL}/category`, categoryData, { headers: { "Content-Type": "multipart/form-data" } });

    return response.data;
  },

  async deleteCategory(categoryId) {
    const apiAxios = createApi();
    const response = await apiAxios.delete(`${BASE_URL}/category`, {
      params: {
        categoryId,
      },
    });

    return response.data;
  },

  async getAllSendlers() {
    const apiAxios = createApi();
    const response = await apiAxios.get(`${BASE_URL}/sendler`);

    return response.data;
  },

  async stopSendler(sendlerId) {
    const apiAxios = createApi();
    const response = await apiAxios.post(`${BASE_URL}/stopsendler/${sendlerId}`);

    return response.data;
  },

  async getAllUsers() {
    const apiAxios = createApi();
    const response = await apiAxios.get(`${BASE_URL}/user`);

    return response.data;
  }
};

export default api;
