import * as Yup from 'yup';
import { logisticMethod as defaultLogistcikMethod } from '../utils/consts';

const phoneRegExp = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g;

export const ValidationSchemaCalcLead = Yup.object().shape({
    fullname: Yup.string()
        .min(2, 'Слишком короткое поле')
        .max(50, 'Длинное значение')
        .required('Обязательное поле'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Некорректный номер телефона')
        .required('Обязательное поле'),
    city: Yup.string()
        .min(2, 'Слишком короткое поле')
        .max(50, 'Длинное значение')
        .required('Обязательное поле'),
    logisticMethod: Yup.string()
        .required('Обязательное поле'),
    pvz: Yup.string()
        .min(2, 'Слишком короткое поле')
        .max(150, 'Длинное значение')
        .when('logisticMethod', (logisticMethod, schema) => {
            if (logisticMethod[0] === defaultLogistcikMethod.cdek)
                return schema.required("Обязательное поле")
            return schema
        }),
    note: Yup.string()
        .max(150, 'Длинное значение'),
    zipcode: Yup.string()
        .when('logisticMethod', (logisticMethod, schema) => {
            if (logisticMethod[0] === defaultLogistcikMethod.post) {
                return schema.min(2, 'Слишком короткое поле')
                    .max(50, 'Длинное значение').required("Обязательное поле")
            }
            return schema
        }),
    address: Yup.string()
        .when('logisticMethod', (logisticMethod, schema) => {
            if (logisticMethod[0] === defaultLogistcikMethod.post) {
                return schema.min(2, 'Слишком короткое поле')
                    .max(50, 'Длинное значение').required("Обязательное поле")
            }
            return schema
        }),
});